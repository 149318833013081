import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LazyBackground from "../components/lazyBackground"
import marked from "marked"
import "../scss/modules/equipe-component.scss"
import { graphql } from "gatsby"
import FloatingBtn from "../components/floating-btn"

const IndexPage = ({ data }) => (
  <Layout>
    <div className={"equipe-page"}>
      <Seo
        title={data.allStrapiSeoEquipe.nodes[0].SEO_titre}
        description={data.allStrapiSeoEquipe.nodes[0].SEO_description}
      />
      <ul className={"item-wrapper col-md-11"}>
        {data.allStrapiEquipes.nodes
          .sort((a, b) => (a.poids > b.poids ? 1 : -1))
          .map((document, key) => (
            <li key={key} className={"item-equipe"}>
              {document.Linkedin ? (
                <a href={document.Linkedin}>
                  <LazyBackground
                    className={"item-content"}
                    source={document.photo}
                  />
                </a>
              ) : (
                <LazyBackground
                  className={"item-content"}
                  source={document.photo}
                />
              )}
              <div className={"item-content"}>
                <h2>{document.nom}</h2>
                <h3>{document.poste}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(document.description),
                  }}
                ></div>
              </div>
            </li>
          ))}
        <div className={"circles"}>
          <span className={"circlesOne"}></span>
          <span className={"circlesTwo"}></span>
          <span className={"circlesThird"}></span>
          <span className={"circlesFourth"}></span>
          <span className={"circlesFifth"}></span>
        </div>
      </ul>
      <FloatingBtn/>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query QueryEquipes {
    allStrapiEquipes {
      nodes {
        nom
        poste
        description
        poids
        photo {
          url
        }
        Linkedin
      }
    }
    allStrapiSeoEquipe {
      nodes {
        SEO_titre
        SEO_description
      }
    }
  }
`
